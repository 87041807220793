<template>
  <b-form @submit.prevent class="view-4 g-mb-2">
    <div class="row d-flex">
      <div class="col col-a">
        <section>
          <h2 class="h-style-1">{{ translations.tcExpires }}</h2>
          <div class="calendar" v-if="!!announcementForm.expires">
            <iCalendar />
            &nbsp; {{ expireDate }}
          </div>
        </section>
        <section>
          <h2 class="h-style-1">{{ translations.tcTitle }}</h2>
          <p>{{ announcementForm.title }}</p>
        </section>
        <section>
          <h2 class="h-style-1">{{ translations.tcMessage }}</h2>
          <p class="show-linebreaks">{{ announcementForm.message }}</p>
        </section>
        <section>
          <h2 class="h-style-1">{{ translations.tcAttachments }}</h2>
          <b-form-group class="form-element form-attachment">
            <div class="attachment-list">
              <span v-if="!validFileArrayLength">{{ translations.tcNone }}</span>
              <span v-if="validFileArrayLength">
                <span
                  v-for="(file, indx) in [...announcementForm.fileArray, ...announcementForm.tempFileArray]"
                  :key="indx"
                >
                  <iPaperclip />
                  <span>{{ file.name }}</span>
                </span>
              </span>
            </div>
          </b-form-group>
        </section>
        <section>
          <h2 class="h-style-1">{{ translations.tcSendNotification }}</h2>
          <ul class="no-bullets">
            <li>{{ announcementForm.notification ? this.translations.tcYes : this.translations.tcNo }}</li>
          </ul>
        </section>
      </div>
      <div class="col col-b">
        <section v-if="validSection">
          <h2 class="h-style-1">{{ translations.tcCommitteeDistribution }}</h2>
          <ul class="no-bullets">
            <li>
              <dl>
                {{ translations.tcCommittees }}
                <dt v-for="(sr, k) in selectedCommittees.areas" :key="k">&bull; {{ sr.text }}</dt>
              </dl>
            </li>
          </ul>
        </section>
        <section v-if="!!state_country_object && !!state_country_object.full">
          <h2 class="h-style-1">{{ translations.tcGeographicDistribution }}</h2>
          <div v-if="d_recipients === 'Camp'">{{ translations.tcCamp }}</div>
          <div v-if="d_recipients === 'Full'">{{ state_country_object.full }}</div>
          <div v-if="d_recipients === 'Selected'">
            <ul class="no-bullets">
              <li v-for="(sr, k) in selectedStateRegions" :key="k">
                <dl>
                  {{ sr.region }}
                  <dt v-for="(ar, j) in sr.areas" :key="j">&bull; {{ ar.area }}</dt>
                </dl>
              </li>
            </ul>
          </div>
        </section>
        <section v-if="validSelectedCommitteeCountries">
          <h2 class="h-style-1">{{ translations.tcGeographicDistribution }}</h2>
          <div v-if="cmt_rgn_both > 0">
            <ul class="no-bullets">
              <li>
                <dl>
                  {{ translations.tcSelectedRegionalCommittees }}
                  <dt v-for="(sr, k) in selectedCommitteeCountries.areas" :key="k">&bull; {{ sr.text }}</dt>
                </dl>
              </li>
            </ul>
          </div>
        </section>
        <section>
          <h2 class="h-style-1">{{ translations.tcAudienceDistribution }}</h2>
          <ul class="no-bullets">
            <li v-for="(sl, k) in validAudience" :key="k">
              <dl>
                {{ sl.title }}
                <span v-if="!!sl[k]">
                  <dt v-for="(dt, m) in selectedCommitteeMembers(sl[k].areas)" :key="m">&bull; {{ dt.text }}</dt>
                </span>
                <span v-if="!!sl.areas">
                  <dt v-for="(dt, m) in selectedCommitteeMembers(sl.areas)" :key="m">&bull; {{ dt.text }}</dt>
                </span>
              </dl>
            </li>
            <li v-if="validSelectedUserCommittees">
              <dl>
                {{ translations.tcSelectedRegionalCommitteeMembers }}
                <dt v-for="(kl, v) in selectedUserCommittees.areas" :key="v">&bull; {{ kl.text }}</dt>
              </dl>
            </li>
          </ul>
        </section>
        <section v-if="d_recipients === 'Selected' || l_recipients === 'Camp' || announcementForm.includeElected">
          <h2 class="h-style-1">
            {{
              state_country_object.full === 'Full State'
                ? translations.tcIncludeStateLeaders
                : translations.tcIncludeNationalLeaders
            }}
          </h2>
          <ul class="no-bullets">
            <li>{{ includeOfficers ? this.translations.tcYes : this.translations.tcNo }}</li>
          </ul>
        </section>
      </div>
    </div>
  </b-form>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import iCalendar from '@/assets/svgs/iCalendar.vue'
import iPaperclip from '@/assets/svgs/iPaperclip.vue'
import reviewIcon from '@/assets/svgs/review-icon.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'announcement-review',
  mixins: [translationMixin],
  props: {
    audienceDistribution: {
      type: Array,
      default: null,
    },
    selectedCommittees: {
      default: null,
    },
    selectedCommitteeCountries: {
      default: null,
    },
    selectedUserCommittees: {
      default: null,
    },
    filteredItems: {
      default: null,
    },
    state_country_object: {
      default: null,
    },
    cmt_rgn_both: {
      type: Number,
      default: null,
    },
    d_recipients: {
      type: String,
      default: null,
    },
    l_recipients: {
      type: String,
      default: null,
    },
    includeOfficers: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateOptions: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'long',
      },
      translations: {},
    }
  },
  methods: {
    selectedCommitteeMembers(ar) {
      let arf = ar.filter((far) => far.selected)
      let ar1 = ar.filter((fa1) => fa1.all === 1)
      return arf.length === 0 ? ar1 : arf
    },
  },
  computed: {
    ...mapGetters({
      announcementForm: 'announcements/announcementForm',
      prefCulture: 'user/userPreferredCulture',
      selectedStateRegions: 'announcements/selectedStateRegions',
    }),
    expireDate() {
      return new Date(this.announcementForm.expires).toLocaleString(this.prefCulture, this.dateOptions)
    },
    validAudience() {
      return this.audienceDistribution.map((amp) => {
        if (typeof amp === 'object') {
          return amp
        } else {
          return amp
            .filter((adf) => !!adf.areas && adf.areas.length > 0)
            .map((fad) => {
              return { title: fad.title, areas: fad.areas }
            })
        }
      })
    },
    validSelectedUserCommittees() {
      return (
        !!this.selectedUserCommittees &&
        !!this.selectedUserCommittees.areas &&
        this.selectedUserCommittees.areas.length > 0
      )
    },
    validSelectedCommitteeCountries() {
      return (
        this.selectedCommitteeCountries !== null &&
        this.selectedCommitteeCountries.areas !== null &&
        this.selectedCommitteeCountries.areas.length > 0
      )
    },
    validSection() {
      return !!this.selectedCommittees && !!this.selectedCommittees.areas && !!this.audienceDistribution
    },
    validFileArrayLength() {
      return [...this.announcementForm.fileArray, ...this.announcementForm.tempFileArray].length > 0
    },
  },
  async created() {
    return await this.getComponentTranslations('announcement-review').then((response) => {
      this.$set(this, 'translations', response['announcement-review'])
    })
  },
  components: {
    iCalendar: iCalendar,
    iPaperclip: iPaperclip,
    reviewIcon: reviewIcon,
    iTooltip: iTooltip,
  },
}
</script>

<style lang="scss" scoped>
p.show-linebreaks {
  white-space: pre-line;
}
</style>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

dt {
  padding-left: 20px;
}
.add-state-announcement {
  .g-mb-1 {
    margin-bottom: 42px;
  }
  .g-mb-2 {
    margin-bottom: 60px;
    @include breakpoint(sm) {
      margin-bottom: 45px;
    }
  }
  .i-tooltip {
    vertical-align: super;
    svg path {
      fill: #000 !important;
    }
  }
  .view-1 {
    @include breakpoint(sm) {
      .btn-group {
        width: 100%;
      }
    }
  }
  .view-2 {
    .custom-control-inline {
      display: flex;
      margin-left: 30px;
      margin-bottom: 0;
      align-items: center;
      @include breakpoint(sm) {
        margin-left: 0;
      }
    }
    @include breakpoint(sm) {
      .btn-group {
        flex-wrap: wrap;
        width: 100%;
        .btn {
          width: 50%;
          flex: 0 1 auto;
          padding: 12px 5px;
        }
      }
      .member-type {
        flex-wrap: wrap;
        .btn-group {
          margin-bottom: 30px;
          @include breakpoint(sm) {
            // margin-bottom: ;
          }
          .btn {
            width: 33%;
          }
        }
      }
    }
  }
  .view-3 {
    input::placeholder {
      text-transform: uppercase;
      color: #636363;
      font-weight: bold;
    }
  }
  .view-4 {
    .col {
      display: block;
    }
    .col-a {
      max-width: 60%;
      flex-basis: 60%;
      @include breakpoint(sm) {
        max-width: 100%;
        flex-basis: 100%;
        margin-bottom: 30px;
      }
    }
    .col-b {
      max-width: 40%;
      flex-basis: 40%;
      @include breakpoint(sm) {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
    section {
      margin-top: 20px;
      margin-bottom: 30px;
    }
    p {
      margin-bottom: 22px;
      font-size: 16px;
      line-height: 22px;
    }
    .form-attachment {
      margin-bottom: 10px;
    }
    .calendar {
      display: flex;
      align-items: center;
      svg {
        margin-right: 15px;
        path {
          fill: #636363 !important;
        }
      }
    }
  }
  .send-notifications {
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .form-attachment {
    .attachment-list {
      margin-bottom: 20px;
      span {
        margin: 0 30px 0 10px;
        color: #1e68fb;
      }
      svg:last-of-type {
        width: 14px;
        height: 14px;
      }
      @include breakpoint(sm) {
        .btn {
          width: auto !important;
        }
      }
    }
  }
}
.page-navigation {
  display: flex;
  align-items: center;
  padding: 30px 80px 100px;
  @include breakpoint(sm) {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 30px 0 30px;
  }
  .nav-item {
    position: relative;
    @include breakpoint(sm) {
      width: 100%;
    }
    .icon {
      svg {
        fill: #8c8c8c !important;
      }
    }
    &.active {
      .icon {
        background-color: $secondary-color;
        svg {
          fill: #000000 !important;
        }
      }
      .text {
        color: #000;
      }
    }
  }
  .sep {
    height: 9px;
    flex: 1 1 auto;
    background-color: #ccc;
    &.active {
      background-color: $secondary-color;
    }
    @include breakpoint(sm) {
      position: relative;
      height: 30px;
      width: 9px;
      flex: 0 0 auto;
      left: 27px;
      transform: translateX(-50%);
    }
  }
  .icon {
    position: relative;
    height: 54px;
    width: 54px;
    flex: 0 0 auto;
    background-color: #ccc;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text {
    display: block;
    position: absolute;
    width: 140px;
    top: 100%;
    left: 50%;
    margin-top: 13px;
    transform: translateX(-50%);
    color: #8c8c8c;
    font-family: $open-sans;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.6px;
    line-height: 22px;
    text-align: center;
    @include breakpoint(sm) {
      width: calc(100% - 70px);
      left: 70px;
      top: 50%;
      margin-top: 0;
      transform: translateY(-50%);
      text-align: left;
    }
  }
}
.checkbox-row {
  display: flex;
  flex-wrap: wrap;
  .checkbox-block {
    min-width: 275px;
    margin-right: 20px;
    padding: 24px 20px 35px 30px;
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    @include breakpoint(sm) {
      width: 100%;
      margin-bottom: 15px;
      margin-right: 0;
    }
  }
  .bg-block-h {
    display: flex;
    margin-bottom: 15px;
    h3 {
      flex: 1 1 auto;
      margin: 0;
      color: $gray-200;
      font-family: $open-sans;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: initial;
      line-height: 22px;
      text-transform: none;
    }
    .toggle {
      height: 25px;
      width: 25px;
      flex: 0 0 auto;
      border: 2px solid $gray-200;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:before,
      &:after {
        content: '';
        display: block;
        width: 7px;
        height: 2px;
        border-radius: 2px;
        transform: rotate(45deg);
        transform-origin: bottom;
        background-color: $gray-200;
        right: -1px;
        position: relative;
      }
      &:after {
        left: -1px;
        right: auto;
        transform: rotate(-45deg);
      }
    }
  }
  label {
    font-size: 16px;
    line-height: 22px;
  }
}
</style>
